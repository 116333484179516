import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './characters.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IAFKCharacter } from '../../modules/common/model/graphql-types';
import {
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faBriefcaseMedical,
  faEye,
  faHandFist,
  faShield,
  faSortAlphaUp,
  faVirus,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Alert, Card } from 'react-bootstrap';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { AFKCharacter } from '../../modules/afk/common/components/afk-character';
import { RatingBox } from '../../modules/common/components/rating-box';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    }
  }
};

interface IAfkCharacterNodes {
  nodes: IAFKCharacter[];
}

interface IAfkCharacterEntry {
  allCharacters: IAfkCharacterNodes;
}

interface IProps {
  data: IAfkCharacterEntry;
}

const AFKTier: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [tableMode, setTableMode] = useState('Pyramid');
  const [activeFilters, setActiveFilters] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'faction',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Graveborn',
            tooltip: 'Graveborn',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_grave.png"
                width={24}
                alt="Graveborn"
              />
            )
          },
          {
            value: 'Lightbearer',
            tooltip: 'Lightbearer',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_light.png"
                width={24}
                alt="Lightbearer"
              />
            )
          },
          {
            value: 'Mauler',
            tooltip: 'Mauler',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_mauler.png"
                width={24}
                alt="Mauler"
              />
            )
          },
          {
            value: 'Wilder',
            tooltip: 'Wilder',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_wilder.png"
                width={24}
                alt="Wilder"
              />
            )
          },
          {
            value: 'Celestial',
            tooltip: 'Celestial',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_cele.png"
                width={24}
                alt="Celestial"
              />
            )
          },
          {
            value: 'Hypogean',
            tooltip: 'Hypogean',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_hypo.png"
                width={24}
                alt="Hypogean"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Marksman',
            tooltip: 'Marksman',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mark.png"
                width={24}
                alt="Marksman"
              />
            )
          },
          {
            value: 'Rogue',
            tooltip: 'Rogue',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_rogue.png"
                width={24}
                alt="Rogue"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      {
        key: 'damage',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Magic',
            tooltip: 'Magic',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_magic.png"
                width={24}
                alt="Magic"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_phys.png"
                width={24}
                alt="Physical"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.damage && activeFilters.damage !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType.toLowerCase() === activeFilters.damage.toLowerCase()
        );
      }
      if (activeFilters.range && activeFilters.range !== 'all') {
        filtered = filtered.filter((emp) => emp.range === activeFilters.range);
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.faction &&
            emp.faction.length > 0 &&
            emp.faction.indexOf(activeFilters.faction) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === 'afk') {
      filtered = filtered.sort((a, b) =>
        a.ratings.afk < b.ratings.afk ? 1 : -1
      );
    } else if (sortOption === 'dream_crystal') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_crystal < b.ratings.dream_crystal ? 1 : -1
      );
    } else if (sortOption === 'dream_orson') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_orson < b.ratings.dream_orson ? 1 : -1
      );
    } else if (sortOption === 'dream_overall') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_overall < b.ratings.dream_overall ? 1 : -1
      );
    } else if (sortOption === 'dream_yeti') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_yeti < b.ratings.dream_yeti ? 1 : -1
      );
    } else if (sortOption === 'dream_brute') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_brute < b.ratings.dream_brute ? 1 : -1
      );
    } else if (sortOption === 'dream_creeper') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_creeper < b.ratings.dream_creeper ? 1 : -1
      );
    } else if (sortOption === ' dream_illucia') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_illucia < b.ratings.dream_illucia ? 1 : -1
      );
    } else if (sortOption === 'pvp') {
      filtered = filtered.sort((a, b) =>
        a.ratings.pvp < b.ratings.pvp ? 1 : -1
      );
    } else if (sortOption === 'dream_thal') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_thal < b.ratings.dream_thal ? 1 : -1
      );
    } else if (sortOption === 'dream_wolf') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_wolf < b.ratings.dream_wolf ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const changeMode = (e) => {
    setTableMode(e);
    if (tableMode === 'Pyramid') {
      setSortOption('dream_overall');
    } else {
      setSortOption('afk');
    }
  };

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'afk', label: 'AFK Stages' },
    { value: 'dream_overall', label: 'Dream Realm (Overall)' },
    { value: 'pvp', label: 'PVP' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  useEffect(() => {
    setSortOption('afk');
  }, []);

  return (
    <DashboardLayout className={'generic-page tier-afk '} game="afk">
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/afk/categories/category_tier.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Tier List for best characters</h1>
          <h2>
            Our AFK Journey tier list rates all characters by their performance
            in AFK Stages, Dream Realm and PVP! Create the best team with our
            tier list!
          </h2>
          <p>
            Last updated: <strong>14/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-page">
        <SectionHeader title="AFK Journey Tier list" />
        <p className="intro">
          The AFK Journey tier list is based on the{' '}
          <strong className="red">patch 1.3.0</strong> (Season 3) and we will
          continue updating it with every balance patch that happens. Please
          keep in mind that AFK Journey{' '}
          <strong>is a game where team building matters most</strong> and while
          in the early game, you can get carried by one or two strong character,
          the further you progress into the game, the less individual strength
          will matter and you will need to build proper teams - based on
          synergy, faction bonuses etc.
        </p>
        <p className="intro">
          Special thanks to{' '}
          <StaticImage
            src="../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> community that
          supports AFK Arena and AFK Journey. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . They do a lot of theorycrafting and testing for AFK Journey and
          their findings helped us shape the tier list.
        </p>
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Tier list criteria</Accordion.Header>
            <Accordion.Body>
              <p>
                Since in AFK Journey there's a lot of different modes, and some
                characters shine only in some of them, we have decided to split
                the ratings between a number of tier lists:
              </p>
              <ul>
                <li>
                  <strong>AFK Stages</strong> - how the character performs in
                  AFK Stages - so the main progression of the game. As long as
                  you're playing below or on equal power level as the enemy
                  team, you won't have that much issues with passing the stage,
                  but the moment you have to fight higher power and level
                  enemies, things change. This rating shows how good the
                  characters are when fighting way below enemy power level.
                  Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li>
                <li>
                  <strong>Dream Realm</strong> - how the character performs in
                  the boss-killing mode where you get better rewards for doing
                  more damage to them. Each of the four available bosses has its
                  own rating as they require unique approaches to tackle them,
                </li>
                <ul>
                  <li>
                    For all bosses we're currently looking at them from the late
                    game perspective, so{' '}
                    <strong className="supreme">Supreme+</strong> (all skills
                    unlocked and EX weapon at +10).
                  </li>
                </ul>
                {/* <li>
                  <strong>Battle Drills</strong> - how the character performs in
                  Battle Drill. This rating averages the performance in trash
                  nodes and against the bosses. Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li> */}
                <li>
                  <strong>PVP</strong> - how the character performs in PVP.
                  Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li>
              </ul>
              <p>Character categories:</p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    Dealer
                  </strong>{' '}
                  - Characters that focus on dealing damage whose kit mainly
                  revolves around that,
                </li>
                <li>
                  <strong className="specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </strong>{' '}
                  - Characters that are hybrids - combination of other roles.
                  Most often they are damage dealers who can provide buffs to
                  allies, debuff enemies or Crowd Control them,
                </li>
                <li>
                  <strong className="support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Supports
                  </strong>{' '}
                  - Characters that focus on healing their allies or providing
                  them with buffs (or both at the same time),
                </li>
                <li>
                  <strong className="tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tanks
                  </strong>{' '}
                  - Characters that are the vanguard of the team and focus on
                  keeping their allies safe by standing in front of them.
                </li>
              </ul>
              <p>Our ratings explained:</p>
              <ul>
                <li>
                  <strong>S+</strong> - The character is simply overpowered and
                  dominates in their role,
                </li>
                <li>
                  <strong>S</strong> - a great and versatile character that has
                  barely any flaws,
                </li>
                <li>
                  <strong>A</strong> - a good character that has a few little
                  flaws,
                </li>
                <li>
                  <strong>B</strong> - a decent character that has a few big
                  flaws holding them back,
                </li>
                <li>
                  <strong>C</strong> - underwhelming character.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Changelog</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>14/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Callan]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>03/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Valka]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>20/01/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Twins]</strong> to the tier list.
                </li>
                <li>
                  Lowered <strong>[Lorsan]</strong> ratings half a tier from S+
                  to S in AFK and PVP. While he can be slotted in multiple
                  existing teams in both modes and he improves their performance
                  - or introduces new variants - he's never the key element in
                  them that makes them tick which is what S+ rating entails.
                </li>
                <ul>
                  <li>
                    This is the first time we are adjusting the ratings down in
                    that way - the PTR server it seems isn't that reliable when
                    it comes to properly testing a character. Moving forward, we
                    won't rely on it anymore. Instead, as usual we will wait for
                    the character to release in the Global server, test it
                    there, and only then add a review and rate the character.
                  </li>
                </ul>
              </ul>
              <p>
                <strong>16/01/2025</strong>
              </p>
              <p>Tier list has been revamped for Season 3.</p>
              <p>
                <strong>03/01/2025</strong>
              </p>
              <ul>
                <li>
                  <strong>[Bonnie]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>22/12/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Hugin]</strong> has been added to the tier list,
                </li>
                <li>
                  We have updated the AFK and PVP tier list to better reflect
                  the meta shift and also to make the tier lists less top-heavy.
                </li>
                <li>AFK Stages:</li>
                <ul>
                  <li>
                    Sonja S+ &gt; S (Not as prevalent as other S+ characters but
                    still very good for AFK stages)
                  </li>
                  <li>Nara A &gt; S (Works well even at 1 copy)</li>
                  <li>
                    Scarlita S+ &gt; S (At some point enemies get so tanky its
                    hard to make use of her execute)
                  </li>
                  <li>Lenya S &gt; A (Kinda squishy)</li>
                  <li>
                    Florabelle S &gt; A (Fell down in usage for AFK stages)
                  </li>
                  <li>
                    Antandra S &gt; A (We have more good tank options now)
                  </li>
                  <li>
                    Lumont A &gt; S (Still good for the dedicated AFK pushers)
                  </li>
                  <li>Lyca B &gt; C (Not used)</li>
                  <li>Niru B &gt; C (Not used)</li>
                  <li>
                    Mikola A &gt; B (Dies too easily; her field alone is often
                    not good enough to justify slotting her instead of another
                    support)
                  </li>
                  <li>
                    Ludovic A &gt; B (Doesn't offer enough utility to be a
                    mainstay for AFK stages)
                  </li>
                  <li>Hewynn S &gt; A (Too slow)</li>
                  <li>Damian S &gt; A (Doesn't heal enough)</li>
                  <li>
                    Cassadee B &gt; A (Great with Lightbearer tiles to spam CC)
                  </li>
                  <li>
                    Odie S+ &gt; S (Still very good but not quite Harak/Lily
                    May/Arden)
                  </li>
                </ul>
                <li>PVP:</li>
                <ul>
                  <li>Dionel S+ &gt; S (To account for Harak meta)</li>
                  <li>
                    Dunlingr S+ &gt; S (He still shuts down a lot of teams by
                    himself, but he's not crucial key of the meta)
                  </li>
                  <li>Granny S &gt; A (Dies to Odie too easily)</li>
                  <li>
                    Lucius A &gt; B (Just a niche pick in Supreme Arena
                    nowadays)
                  </li>
                  <li>
                    Lucca A &gt; B (Very tanky but not good at protecting his
                    team)
                  </li>
                  <li>
                    Sinbad S &gt; A (Can die early before getting his debuffs
                    out)
                  </li>
                  <li>
                    Sonja S &gt; A (Awkward positioning requirements make her
                    hard to use)
                  </li>
                  <li>
                    Carolina S &gt; A (Not as good as Arden - dies to Lily May)
                  </li>
                  <li>Berial S &gt; A (Fell out of the meta after Harak)</li>
                  <li>
                    Seth A &gt; B (Squishy - doesn't really get to snowball like
                    he wants to)
                  </li>
                  <li>Cecia A &gt; B (Too slow for current meta)</li>
                  <li>Viperian A &gt; B (Powercrept by better DPS carries)</li>
                  <li>
                    Marilee B &gt; C (Takes too long to ramp up + squishy)
                  </li>
                  <li>
                    Salazer B &gt; C (His CC is not good enough to include him
                    in your team)
                  </li>
                  <li>
                    Bryon A &gt; B (His attacks often get nullified by Thoran,
                    and are not strong enough to kill Phraesto)
                  </li>
                  <li>
                    Lenya S &gt; A (Needs open maps to perform well; prone to
                    getting stuck in walls)
                  </li>
                  <li>Korin B &gt; C (Takes too long to ramp up + squishy)</li>
                </ul>
              </ul>
              <p>
                <strong>06/12/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated the Dream Realm ratings to include the impact
                  of <strong>Sonja</strong> on the meta.
                </li>
              </ul>
              <p>
                <strong>01/12/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Sonja]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>27/11/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated the Dream Realm ratings to include the impact
                  of <strong>Harak</strong> on the meta.
                </li>
                <li>
                  The following character's ratings have been lowered:{' '}
                  <strong>Reinier</strong>, <strong>Talene</strong>,{' '}
                  <strong>Lily May</strong> and <strong>Odie</strong>.
                </li>
                <li>
                  The following character's ratings have been increased:{' '}
                  <strong>Shakir</strong> and <strong>Ludovic</strong>.
                </li>
              </ul>
              <p>
                <strong>25/11/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Harak]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>17/11/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Hodgkin]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>04/11/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Lucca]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>29/10/2024</strong>
              </p>
              <p>
                Updated all Dream Realm ratings to the Endless meta since we
                were able to reach it and test units over the rotation last
                week.
              </p>
              <p>
                <strong>19/10/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Tasi]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>18/09/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Nara]</strong> and <strong>[Dunlingr]</strong> have
                  been added to the tier list,
                </li>
                <li>
                  The tier list has been revamped and updated for Season 2. A
                  lot of characters ratings have changed as the Season 1 Skills
                  and Season 1 Charms have been removed and they had a big
                  impact on some ratings.
                </li>
              </ul>
              <p>
                <strong>10/09/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Lenya]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>30/08/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Mikola]</strong> remaining ratings have been added
                  and we also made several changes to various Dream Realm
                  ratings for other characters.
                </li>
              </ul>
              <p>
                <strong>25/08/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Mikola]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>15/08/2024</strong>
              </p>
              <ul>
                <li>
                  Added remaining <strong>[Lily May]</strong> ratings and
                  adjusted the Dream Realm ratings for every other character.
                </li>
              </ul>
              <p>
                <strong>11/08/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Lily May]</strong> has been added to the tier list
                  and we have updated some Dream Realm ratings to show her
                  impact on the meta.
                </li>
              </ul>
              <p>
                <strong>15/07/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Ludovic]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>11/07/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated all <strong>Story</strong> ratings to better
                  showcase the progression meta for new players.
                </li>
              </ul>
              <p>
                <strong>06/07/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Talene]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>24/06/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated <strong>Dream Realm</strong> ratings for the
                  seasonal meta - now the ratings includes the Endless meta too
                  where a lot of things had changed again.
                </li>
              </ul>
              <p>
                <strong>17/06/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated{' '}
                  <strong>
                    AFK Stages (Pre-EX, EX and Deficit), PVP and Battle Drill
                  </strong>{' '}
                  ratings for the seasonal stuff discovered so far! Most
                  characters ratings have been adjusted, so making a proper
                  changelog is impossible - though we did updated the
                  mini-reviews for more than half of the characters that give
                  some insights into how they fit into the seasonal meta.
                </li>
              </ul>
              <p>
                <strong>09/06/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Phraesto]</strong> has been added to the tier list.
                </li>
                <li>
                  Renamed the Trial of Abyss category into{' '}
                  <strong>AFK (Deficit)</strong>. Trial of Abyss became a joke
                  with the start of the season, but we rated characters there
                  under very high deficit, so we will repurpose the ratings for
                  the new category. And High Deficit means enemies with 4-5x
                  your power, so basically what the 'whales' are pushing.
                </li>
              </ul>
              <p>
                <strong>01/06/2024</strong>
              </p>
              <ul>
                <li>
                  <strong>[Soren]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                <strong>27/05/2024</strong>
              </p>
              <ul>
                <li>
                  We have updated all Dream Realm ratings - basically every
                  character had their ratings adjusted, so we won't be listing
                  all of them here,
                </li>
                <li>
                  We have added two new Dream Realm sub-categories: Wolf and
                  Alpha.
                </li>
              </ul>
              <p>
                <strong>14/05/2024</strong>
              </p>
              <p>
                Added <strong>Alsa</strong> to the tier list. We are still
                testing all the other characters to include seasonal changes and
                the tier list will be updated soon!
              </p>
              <p>
                <strong>08/05/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="brutus" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Brutus rating from B to S in Tower of Abyss.
                  At the massive 100+ level deficit most tanks falter easily and
                  Brutus can always guarantee your team a few crucial seconds.
                  He dies instantly after, no matter the investment you put into
                  him, but the seconds he managed to buy you matter a lot.
                </li>
              </ul>
              <p>
                <strong>29/04/2024</strong>
              </p>
              <p>
                We have reworked the story tier list. Now we rate the characters
                by their performance before obtaining EX weapon and after
                instead of the artificial stage split. We have also added Trial
                of Abyss category where we rate maxed characters by their
                performance in this mode under big level deficit (100+).
              </p>
              <p>
                <strong>22/04/2024</strong>
              </p>
              <p>
                We have added the Battle Drills category. This rating averages
                the performance of all characters in both trash nodes and
                against the bosses - so it favorites characters who can deal
                both AoE and single target damage.
              </p>
              <p>
                <strong>20/04/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Scarlita rating from S to S+ in Story Late.
                  Once invested, she's one of the best characters for late story
                  and Trial of Abyss (which is part of the Late rating). Her
                  ability to support the team and insta-kill opponents helps a
                  lot in the toughest stages in the game currently.
                </li>
              </ul>
              <p>
                <strong>18/04/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                  has been added to the tier list. You can consider Florabelle
                  as Cecia's sister. Both focus on summons and their performance
                  is very similar across the modes - they have the same
                  advantages and disadvantages. Still, what sets Flora apart is
                  the synergy with other summoners once you get her EX to +10.
                </li>
              </ul>
              <p>
                <strong>17/04/2024</strong>
              </p>
              <ul>
                <li>
                  After observing how the PVP meta formed on S1-S7 servers, we
                  have decided to make several adjustments to the tier list,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="arden" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Arden rating from S to S+. Arden is the core
                  member of the Eironn CC/nuke team and currently, he has no
                  replacement there which makes him worthy of the top rating,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Cecia rating from S to A. While Cecia is
                  still very strong in the early and mid game, once you compare
                  characters at S+ ascension, she fails behind other options.
                  Even with Rowan boosting her, she's often too slow to make a
                  difference against the current top meta teams, mainly the
                  Eironn one,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Koko rating from S to A. Sadly, Koko takes
                  too long to use her ultimate and it's not enough to counter
                  Eironn CC team. PVP is all about speed at the moment and Koko
                  lacks it (but she still can be used in some turtle teams on
                  defense),
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="seth" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Seth rating from S to A. Maulers while being
                  the best faction for PVE progression aren't good enough in PVP
                  anymore. So the Seth + Shakir team that was super popular back
                  in the day struggles with performance now,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Shakir rating from S to A. Same reasoning as
                  with Seth,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="viperian" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Viperian rating from S to A. While back in
                  the PTR days, Viperian often was used in the Eironn team as a
                  source of additional damage, now he was replaced by Silvina
                  who provides more damage and CC and isn't reliant on energy to
                  perform well.
                </li>
              </ul>
              <p>
                <strong>12/04/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Parisa's rating from B/B to S/S in the Mid
                  and Late game Story categories and from A to S in PVP. Parisa
                  low range on her AOE can be fixed by Eironn and
                  coincidentally, she perfectly fits into the meta PVE/PVP
                  Eironn CC comp (with Carolina, Arden and Damian), drastically
                  increasing the damage output of the team. Parisa's damage
                  potential against clumped enemies nearly matches that of
                  Carolina. Just keep in mind that without Eironn, she's very
                  RNG which limits her usage outside of that team.
                </li>
              </ul>
              <p>
                <strong>06/04/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="antandra" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Antandra's rating from A/S to S+/S+ in the
                  Early and Mid game Story categories. Her performance when
                  played under deficit and during the early stages of the game
                  honestly surprised us. She also works in multiple setups and
                  helps keeping her frontline partner alive for the backline to
                  do their thing.
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="granny-dahnie"
                    enablePopover
                  />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Granny's rating from S to A in the Early
                  Story category. At one copy, she dies a bit too fast to help
                  the team and without using her ultimate she struggles. Also
                  her performance is worse than the other tanks at this stage of
                  the game.
                </li>
              </ul>
              <p>
                <strong>04/04/2024</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Damian's rating from A/S to S/S+ in the Mid
                  and Late game Story categories. His performance there
                  surpassed our expectation as the Haste boost and additional
                  healing he gains from the EX weapon often makes a huge
                  difference between passing the stage and failing. Past the
                  500-600 AFK Stage, you will often find Damian in the lowest
                  power clears,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Koko's rating from A/S to S+/S+ in the Early
                  and Mid game Story categories. Mauler teams work very well at
                  the lower end and the partnership of Smokey + Koko makes
                  pushing content under big deficit a lot easier - especially
                  for F2P players who currently are progressing through the
                  early game,
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  We have increased Smokey's rating from S to S+ in the Early
                  Story category. With the raise of Koko and Smokey core, he
                  deserves to be upgraded to the highest rating in the early
                  game,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="vala" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Vala's rating from S+ to A in the Early
                  Story category. While she can be used as an energy vampire
                  with low amount of dupes, her damage simply isn't there and
                  she struggles in the early game compared to other characters.
                  Her Mid and Late game ratings remain the same as with more
                  dupes, and especially her EX weapon, she starts to shine a
                  lot,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Reinier's rating from S+ to S in the Early
                  Story category. Reinier is still super strong, but he's more
                  of a clutch in the early game - you use him when everything
                  else fails and he's rarely the first choice when you're
                  building your early progression team. So we decided to drop
                  him down a rating in the early game only,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  We have decreased Hewynns's rating from S+ to S in the Early
                  Story category. Hewynn in the early game is too slow and often
                  your team will die before she is able to cast her ultimate.
                  This can be circumstanced by pairing her with Rowan, but this
                  also means that she has a flaw that you need to work around -
                  so we feel dropping her one tier is the right move.
                </li>
              </ul>
              <p>
                <strong>24/03/2024</strong>
              </p>
              <p>
                Some characters had their ratings adjusted slightly (1 tier up
                or down). We still will be tinkering with some ratings before
                the release, so expect other smaller changes to happen.
              </p>
              <p>
                <strong>16/03/2024</strong>
              </p>
              <p>
                Added <strong>[Koko]</strong> to the tier list. Koko is an
                amazing support who can reduce the damage her allies take by
                staggering it. Early on, she won't feel that impactful as she's
                not that 'flashy' and because you can overpower whatever you
                face with strong DPS, but later on, her real value will be
                revealed and she will shine in both Story and Dream Realm (and
                even in PVP content as she can remove CC and counter the Eironn
                meta team).
              </p>
              <p>
                <strong>08/03/2024</strong>
              </p>
              <p>
                Tier list has been fully reworked to take into account the
                character progression rework that happened with the patch
                1.0.11. We have also changed the criteria for rating characters
                in all categories, so do check the Criteria tab for the details.
              </p>
              <p>
                <strong>08/02/2024</strong>
              </p>
              <ul>
                <li>Initial release of the tier list.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className="mode-switcher">
          <div className="tier-list-switcher type">
            <div
              className={`option pyramid-variant ${
                tableMode === 'Pyramid' && 'selected'
              }`}
              onClick={() => changeMode('Pyramid')}
            >
              <h6>Generic Tier List</h6>
            </div>
            <div
              className={`option table-variant ${
                tableMode === 'Table' && 'selected'
              }`}
              onClick={() => changeMode('Table')}
            >
              <h6>Dream Realm Only</h6>
            </div>
          </div>
        </div>
        {tableMode === 'Table' ? (
          <>
            <div className="tier-list-header">
              <p className="title">
                Tier List <span>(Dream Realm Only)</span>
              </p>
              <p className="sub-title">
                You're currently viewing the Dream Realm view that shows the
                ratings for each of the 8 available bosses in Season 2 for the
                Endless mode. For other modes, switch to the second category.
              </p>
            </div>
            <Alert variant="primary alert-red ">
              <p>
                <strong>
                  The current Dream Realm ratings are based on the Pre-Endless
                  Dream Realm
                </strong>{' '}
                - in the test server, it was impossible to reach Endless due to
                its short duration. True Damage and HP-based characters (for
                example: Lily May or Phraesto) underperform in Pre-Endless due
                to how the boss defenses and HP scales. They will perform better
                in Endless mode and once we reach it (should take around 40 days
                into the season), the ratings will be updated.
              </p>
            </Alert>
            <div className="employees-filter-bar afk table-view">
              <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
            </div>
            <div className="tier-list-table">
              <div className="tier-list-header">
                <div className="tier-list-header-bottom">
                  <div
                    className={`column character ${
                      sortOption === '+fullName' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('+fullName')}
                  >
                    <p>
                      Character{' '}
                      {sortOption && sortOption === '+fullName' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column ${
                      sortOption === 'dream_overall' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_overall')}
                  >
                    <p>
                      Overall{' '}
                      {sortOption && sortOption === 'dream_overall' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_yeti' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_yeti')}
                  >
                    <p>
                      Snow Stomper{' '}
                      {sortOption && sortOption === 'dream_yeti' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_wolf' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_wolf')}
                  >
                    <p>
                      Lone Gaze{' '}
                      {sortOption && sortOption === 'dream_wolf' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_orson' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_orson')}
                  >
                    <p>
                      Orson{' '}
                      {sortOption && sortOption === 'dream_orson' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_crystal' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_crystal')}
                  >
                    <p>
                      Crystal Beetle{' '}
                      {sortOption && sortOption === 'dream_crystal' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_brute' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_brute')}
                  >
                    <p>
                      Shellbrute{' '}
                      {sortOption && sortOption === 'dream_brute' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_creeper' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_creeper')}
                  >
                    <p>
                      Creeper{' '}
                      {sortOption && sortOption === 'dream_creeper' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_illucia' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_illucia')}
                  >
                    <p>
                      Illucia{' '}
                      {sortOption && sortOption === 'dream_illucia' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_thal' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_thal')}
                  >
                    <p>
                      Thalassa{' '}
                      {sortOption && sortOption === 'dream_thal' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="column info">
                    <p>Info</p>
                  </div>
                </div>
              </div>
              <div className="tier-list-content">
                {visibleCharacters.map((emp, index) => (
                  <div key={index}>
                    <div className="tier-list-row">
                      <div className="column character">
                        <AFKCharacter
                          slug={emp.slug}
                          mode="icon"
                          showLabel
                          enablePopover
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_overall}
                          ratingName="Overall"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_yeti}
                          ratingName="Snow Stomper"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_wolf}
                          ratingName="Lone Wolf"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_orson}
                          ratingName="Orson"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_crystal}
                          ratingName="Crystal Beetle"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_brute}
                          ratingName="Sheelbrute"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_creeper}
                          ratingName="Creeper"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_illucia}
                          ratingName="Illucia"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_thal}
                          ratingName="Thalassa"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column review">
                        {activeText === emp.slug ? (
                          <p onClick={() => setActiveText(null)}>
                            <FontAwesomeIcon icon={faXmark} width="18" />{' '}
                            <span>Hide</span>
                          </p>
                        ) : (
                          <p onClick={() => setActiveText(emp.slug)}>
                            <FontAwesomeIcon icon={faEye} width="18" />{' '}
                            <span>Show</span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="review-mobile">
                      {activeText === emp.slug ? (
                        <p onClick={() => setActiveText(null)}>
                          <FontAwesomeIcon icon={faXmark} width="18" />{' '}
                          <span>Hide info</span>
                        </p>
                      ) : (
                        <p onClick={() => setActiveText(emp.slug)}>
                          <FontAwesomeIcon icon={faEye} width="18" />{' '}
                          <span>Show info</span>
                        </p>
                      )}
                    </div>
                    <div
                      className={`explanation ${
                        activeText === emp.slug ? 'visible' : ''
                      }`}
                    >
                      {emp.tierExplanation ? (
                        <>{renderRichText(emp.tierExplanation, options)}</>
                      ) : (
                        <p>Explanation missing.</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-header">
              <p className="title">
                Tier List -{' '}
                <span>
                  {sortOption === 'afk' && 'AFK Stages'}
                  {sortOption === 'dream_overall' && 'Dream Realm (Overall)'}
                  {sortOption === 'battle' && 'Battle Drill'}
                  {sortOption === 'pvp' && 'PVP'}
                </span>
              </p>
              <p className="sub-title">
                You're currently viewing the{' '}
                <strong className="tier-type">
                  {sortOption === 'afk' && 'AFK Stages'}
                  {sortOption === 'dream_overall' && 'Dream Realm (Overall)'}
                  {sortOption === 'battle' && 'Battle Drill'}
                  {sortOption === 'pvp' && 'PVP'}
                </strong>{' '}
                tier list. It shows{' '}
                {sortOption === 'afk' &&
                  'how the character performs in the Story and AFK Stages after obtaining their EX Weapon and under a high deficit.'}
                {sortOption === 'battle' &&
                  'how the character performs in Battle Drill. This rating averages the performance in trash nodes and against the bosses.'}
                {sortOption === 'dream_overall' && (
                  <>
                    how the character performs in{' '}
                    <strong className="red">Dream Realm (Endless)</strong>.
                  </>
                )}
                {sortOption === 'pvp' &&
                  'how the character performs in PVP - both offense and defense.'}{' '}
                Use the switcher below to view a different tier list.
              </p>
            </div>
            <div className="tier-list-switcher">
              <div
                className={`option afk ${sortOption === 'afk' && 'selected'}`}
                onClick={() => setSortOption('afk')}
              >
                <h6>AFK Stages (Deficit)</h6>
              </div>
              {/* <div
                className={`option battle ${
                  sortOption === 'battle' && 'selected'
                }`}
                onClick={() => setSortOption('battle')}
              >
                <h6>Battle Drills</h6>
              </div> */}
              <div
                className={`option dream-overall ${
                  sortOption === 'dream_overall' && 'selected'
                }`}
                onClick={() => setSortOption('dream_overall')}
              >
                <h6>Dream Realm (Overall)</h6>
              </div>
              <div
                className={`option pvp ${sortOption === 'pvp' && 'selected'}`}
                onClick={() => setSortOption('pvp')}
              >
                <h6>PVP</h6>
              </div>
            </div>
            <Alert variant="primary alert-red ">
              <p>
                <strong>
                  The current Dream Realm ratings are based on the Pre-Endless
                  Dream Realm
                </strong>{' '}
                - in the test server, it was impossible to reach Endless due to
                its short duration. True Damage and HP-based characters (for
                example: Lily May or Phraesto) underperform in Pre-Endless due
                to how the boss defenses and HP scales. They will perform better
                in Endless mode and once we reach it (should take around 40 days
                into the season), the ratings will be updated.
              </p>
            </Alert>
            <div className="employees-filter-bar afk">
              <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
            </div>
            <div className="custom-tier-list-afk">
              <div className="custom-tier-header">
                <div className="tier-rating">
                  <span>&nbsp;</span>
                </div>
                <div className="custom-tier-container">
                  <div className="custom-header dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-header specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-header support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-header tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-s-plus first">
                <div className="tier-rating s-plus">
                  <span>S+</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-s">
                <div className="tier-rating s">
                  <span>S</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297007"
              ></div>
              <div className="custom-tier tier-a">
                <div className="tier-rating a">
                  <span>A</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-b">
                <div className="tier-rating b">
                  <span>B</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-c">
                <div className="tier-rating c">
                  <span>C</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
      </div>
    </DashboardLayout>
  );
};

export default AFKTier;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | AFK Journey | Prydwen Institute"
    description="Our AFK Journey tier list rates all characters by their performance in AFK Stages, Dream Realm and PVP! Create the best team with our tier list!"
    game="afk"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAfkCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        faction
        damageType
        range
        tierListCategory
        tierListTags
        tierExplanation {
          raw
        }
        ratings {
          afk
          pvp
          dream_overall
          dream_yeti
          dream_orson
          dream_crystal
          dream_wolf
          dream_brute
          dream_creeper
          dream_illucia
          dream_thal
        }
      }
    }
  }
`;
